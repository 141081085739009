(function () {
  'use strict';

  angular
    .module('neo.public.calendar.championship')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('public.championshipCalendar', {
        url: '/championship/{championshipId}/calendar',
        templateUrl: 'public/calendar/championship/championship.tpl.html',
        controller: 'ChampionshipCalendarCtrl',
        controllerAs: 'vm',
        resolve: {
          sports: function (Sports) {
            return Sports.query().$promise;
          },
          categories: function (Categories) {
            return Categories.query().$promise;
          },
          genders: function (Genders) {
            return Genders.query().$promise;
          },
          championship: function ($stateParams, ChampionshipPublic) {
            return ChampionshipPublic.get({id: $stateParams.championshipId}).$promise;
          },
          towns: function (Poblacions) {
            return Poblacions.query().$promise;
          },
          championshipCalendar: function ($stateParams, ChampionshipCalendarPublic) {
            return ChampionshipCalendarPublic.get({id: $stateParams.championshipId}).$promise;
          }
        }
      }).state('public.championshipCalendarExcel', {
        url: '/championship/{championshipId}/calendarexcel',
        templateUrl: 'public/calendar/championship/championshipexcel.tpl.html',
        controller: 'ChampionshipCalendarExcelCtrl',
        controllerAs: 'vm',
        resolve: {
          sports: function (Sports) {
            return Sports.query().$promise;
          },
          categories: function (Categories) {
            return Categories.query().$promise;
          },
          genders: function (Genders) {
            return Genders.query().$promise;
          },
          championship: function ($stateParams, ChampionshipPublic) {
            return ChampionshipPublic.get({id: $stateParams.championshipId}).$promise;
          },
          towns: function (Poblacions) {
            return Poblacions.query().$promise;
          },
          championshipCalendar: function ($stateParams, ChampionshipCalendarPublic) {
            return ChampionshipCalendarPublic.get({id: $stateParams.championshipId}).$promise;
          }
        }
      });
  }
}());
